





































































import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as ReceiptFromOikView } from "@monorepo/reports/src/store/modules/receiptFromOikView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import useGetTableLibs from "./composables/useGetTableLibs";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/ReceiptsFromOikView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { convertApiItemToUi } from "@monorepo/reports/src/views/ReceiptsFromOikView/utils/convertApiItemToUi";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/reports/src/views/ReceiptsFromOikView/utils/defaultCurrentFilters";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "receiptFromOikView";

export default defineComponent({
  name: "receiptFromOikView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    InfiniteScroll,
    Table,
    Filters,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
    };
  },
  computed: {
    ...mapGetters(SECTION_NAME, [
      "cells",
      "openedId",
      "infiniteId",
      "isOpenFilters",
      "data",
      "totalLength",
      "isTableLoading",
      "oiksLibrary",
      "typesLibrary",
    ]),
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    oiks(): { title: string; value: string }[] {
      return convertToSelectItems(this.oiksLibrary, "name", "code");
    },
    types(): { title: string; value: string }[] {
      return this.typesLibrary.map((item: string) => {
        return {
          title: item,
          value: item === "Опись" ? "INVENTORY" : "EAD",
        };
      });
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        oiks: this.oiks,
        types: this.types,
      };
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters"]),
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, ReceiptFromOikView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
    });
    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
