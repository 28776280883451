import { fields } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/fields";

export const filtersElements = [
  {
    value: fields.DATE,
    dateValues: [fields.DATE_FROM, fields.DATE_TO],
    title: "Временной интервал",
    type: "datePeriod",
    width: 250,
  },
  {
    value: fields.OIK_CODE,
    title: "ОИК",
    type: "select",
    className: "mt-4 oik-filter",
    selectItemsField: "oiks",
    width: 250,
  },
  {
    value: fields.TK_TYPE,
    title: "Тип документа",
    type: "select",
    selectItemsField: "types",
    width: 250,
  },
];
