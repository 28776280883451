export interface IReceiptsFromOikFields {
  OIK_NAME: string;
  OIK_CODE: string;
  DATE: string;
  DATE_FROM: string;
  DATE_TO: string;
  TK_TYPE: string;
  INDEX: string;
  CREATE_TK_DATE: string;
  TK_RECEIVED_AT: string;
  STATUS_TK: string;
  TK_OIK_ID: string;
  DOC_TYPE: string;
  DOC_HEADER: string;
  REGISTRATION_OIK_DATE: string;
  ARCHIVE_NAME: string;
  FUND_NAME: string;
}

export const fields: IReceiptsFromOikFields = {
  OIK_NAME: "oikName",
  OIK_CODE: "oikCode",
  DATE: "date",
  DATE_FROM: "dateFrom",
  DATE_TO: "dateTo",
  TK_TYPE: "tkType",
  INDEX: "index",
  CREATE_TK_DATE: "createTk",
  TK_RECEIVED_AT: "registrationTk",
  STATUS_TK: "statusTK",
  TK_OIK_ID: "identTk",
  DOC_TYPE: "typeTk",
  DOC_HEADER: "headerDoc",
  REGISTRATION_OIK_DATE: "registrationOik",
  ARCHIVE_NAME: "archName",
  FUND_NAME: "fundName",
};
