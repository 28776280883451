import { Module } from "vuex";
import { IRootState } from "@monorepo/reports/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/reports/src/views/ReceiptsFromOikView/utils/convertFiltersToApi";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { IReportElement } from "@monorepo/reports/src/views/ReceiptsFromOikView/types/reportElement";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { actions } from "@monorepo/utils/src/store/modules/actions";
import { getters } from "@monorepo/utils/src/store/modules/getters";
import { mutations } from "@monorepo/utils/src/store/modules/mutations";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import axios from "axios";
import { GeneratedIntervalTypes, IScheduledReport } from "@monorepo/utils/src/types/IScheduledReport";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";

interface ITableState {
  filters: Partial<ITableFiltersObject>;
  totalLength: number;
  section?: Sections;
  infiniteId: string | null;
  sectionCells: ISection;
  isOpenFilters: boolean;
  data: IReportElement[];
  searchTemplates: ISearchTemplate[];
  cells: string[];
  libraries: {
    oiks: string[];
    types: string[];
  };
  isTableLoading: boolean;
}

const defaultFilters = (): Partial<ITableFiltersObject> => ({
  fieldFilters: {},
  sort: {},
  initMessagesLength: 50,
});

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    sectionCells: {} as ISection,
    infiniteId: null,
    section: Sections.RECEIPT_FROM_OIK,
    totalLength: 0,
    cells: [],
    searchTemplates: [],
    data: [],
    isOpenFilters: false,
    libraries: {
      oiks: [],
      types: ["Документ", "Опись"],
    },
    isTableLoading: false,
  }),
  mutations: {
    ...mutations,
    addOiksLib(state: ITableState, payload: string[]) {
      state.libraries.oiks = payload || [];
    },
    addData(
      state: ITableState,
      payload: {
        data: IReportElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    clearEventList(state: ITableState) {
      state.data = [];
      state.totalLength = 0;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...actions,
    clearEventList({ commit }) {
      commit("clearEventList");
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters as ITableFiltersObject, state.data.length, convertFiltersCustomToApi);
      // const response = await axios.post(QUERY_PATH.GET_REPORT_PREVIEW, params).finally(() => {
      //   commit("setTableLoading", false);
      // });
      // const { data, total: totalLength } = response.data;
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IReportElement[]>(QUERY_PATH.GET_REPORT_PREVIEW, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || data?.length || 0 });
        return { data: state.data || [], totalLength: totalLength || data?.length || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      const params: IConvertFiltersToApiResponse = convertFiltersToApi(
        state.filters as ITableFiltersObject,
        state.data.length,
        convertFiltersCustomToApi,
        true
      );
      params.cells = state.filters.cells || [];
      delete params.sort;
      const queryParams = encodeQueryData(params);
      const query = payload === "CSV" ? QUERY_PATH.GET_REPORT_CSV : payload === "PDF" ? QUERY_PATH.GET_REPORT_PDF : QUERY_PATH.GET_REPORT_XLS;
      if (payload === "PDF") {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(query, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
        return;
      }
      dispatch("app/openNewWindow", getFullPath(query, queryParams), { root: true });
    },
    async getOiks({ commit, state }) {
      checkExistLibrary(state.libraries.oiks, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_OIKS_SHORT_OBJECT_LIB);
        commit("addOiksLib", data);
      });
    },
    async deleteSearchTemplates({ dispatch, rootGetters }, payload: ISearchTemplate) {
      try {
        const isNeedDeleteTemplates = !!rootGetters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.SCHEDULED_REPORT_DELETE;
        });
        await axios.delete(`${QUERY_PATH.GET_SEARCH_TEMPLATE}/${payload.id}`);
        if (isNeedDeleteTemplates) {
          await axios.delete(`${QUERY_PATH.GET_SCHEDULED_REPORT}/${payload.scheduledReport?.id}`);
        }
        await dispatch("getSearchTemplates");
      } catch (e) {
        console.error(e);
      }
    },
    async saveSearchTemplates({ getters, dispatch, rootGetters }, payload: Partial<ISearchTemplate>) {
      try {
        const isNeedCreateTemplates = !!rootGetters["auth/user"]?.authorities?.find((element: { authority: string }) => {
          return element.authority === authorities.SCHEDULED_REPORT_CREATE;
        });
        const templateData = {
          ...payload,
          section: getters?.section,
          username: rootGetters["auth/user"].name,
        };
        delete templateData.scheduledReport;
        const {
          data: { data: template },
        } = await axios.post(QUERY_PATH.GET_SEARCH_TEMPLATE, templateData);
        if (isNeedCreateTemplates) {
          await axios.post(QUERY_PATH.GET_SCHEDULED_REPORT, {
            name: payload.templateName,
            searchTemplateGuid: template?.guid,
            generateInterval: GeneratedIntervalTypes.DAILY,
          });
        }

        await dispatch("getSearchTemplates");
      } catch (e: unknown) {
        showNotification("Ошибка сохранения шаблона поиска: " + (e as { response?: { data?: { message?: string } } }).response?.data?.message ?? "");
        console.error(e);
      }
    },
    async getSearchTemplates({ commit, getters, rootGetters, state }) {
      const isNeedSearchTemplates = !!rootGetters["auth/user"]?.authorities?.find((element: { authority: string }) => {
        return element.authority === authorities.SCHEDULED_REPORT_LIST;
      });
      let scheduledReportsData: IScheduledReport[] = [];
      const { data } = await getQuery<ISearchTemplate[]>(`${QUERY_PATH.GET_SEARCH_TEMPLATE}/${rootGetters["auth/user"].name}/${getters?.section}`);
      if (isNeedSearchTemplates) {
        const { data: scheduledReports } = await getQuery<IScheduledReport[]>(`${QUERY_PATH.GET_SCHEDULED_REPORT}`);
        scheduledReportsData = scheduledReports;
      }

      if (data !== null) {
        let tmp = [] as ISearchTemplate[];
        if (scheduledReportsData !== null) {
          tmp = data.map((item: ISearchTemplate) => {
            item.scheduledReport = scheduledReportsData.find((report: IScheduledReport) => report.searchTemplateGuid === item.guid);
            return item;
          });
        }
        commit("setSearchTemplates", tmp ?? data);
        return { data: state?.searchTemplates || [] };
      }
      return { data: null };
    },
    async updateScheduledReport({ dispatch }, payload: ISearchTemplate) {
      try {
        await axios.put(QUERY_PATH.GET_SCHEDULED_REPORT, payload);
        await dispatch("getSearchTemplates");
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    ...getters,
    oiksLibrary(state: ITableState) {
      return state.libraries.oiks;
    },
    typesLibrary(state: ITableState) {
      return state.libraries.types;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
