import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/receiptFromOikView/fields";

export const tableHeaders = [
  { text: "№ по порядку", defaultWidth: 120, value: fields.INDEX, isLink: false, isSorted: false, isHandle: true, id: uuid() },
  { text: "Дата/Время создание ТК в ОИК", defaultWidth: 200, value: fields.CREATE_TK_DATE, isSorted: false, isHandle: true, id: uuid() },
  {
    text: "Дата/Время поступления ТК в ЦХЭД",
    isLink: false,
    defaultWidth: 200,
    value: fields.TK_RECEIVED_AT,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  { text: "Статус обработки ТК в ЦХЭД", defaultWidth: 200, value: fields.STATUS_TK, isSorted: false, isHandle: true, id: uuid() },
  { text: "Идентификатор ТК в ОИК", defaultWidth: 200, value: fields.TK_OIK_ID, isSorted: false, isHandle: true, id: uuid() },
  { text: "Тип документа (ЭАД/Опись)", defaultWidth: 200, value: fields.DOC_TYPE, isSorted: true, isHandle: true, id: uuid() },
  { text: "Заголовок документа", isLink: false, defaultWidth: 400, value: fields.DOC_HEADER, isSorted: false, isHandle: true, id: uuid() },
  {
    text: "Дата регистрации документа в ОИК",
    isLink: false,
    defaultWidth: 200,
    value: fields.REGISTRATION_OIK_DATE,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  { text: "Краткое название архива", defaultWidth: 200, value: fields.ARCHIVE_NAME, isLink: false, isSorted: false, isHandle: true, id: uuid() },
  { text: "Краткое название фонда", defaultWidth: 200, value: fields.FUND_NAME, isLink: false, isSorted: false, isHandle: true, id: uuid() },
];
